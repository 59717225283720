/* eslint-disable max-lines */
import { TransitDocumentsEnum } from '@e-origin/shared';
import { retrieveCodes } from '../../../../../../../../stores/settingsSlice';

export enum FormDocumentsInputType {
  NUMBER = 'NUMBER',
  INPUT = 'INPUT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  DATE = 'DATE',
}

export interface GoodsItemDocumentFormSchemaProps {
  [TransitDocumentsEnum: string]: {
    fields: {
      [documentPropertyPath: string]: {
        type: FormDocumentsInputType;
        options?: (string | number)[] | { value: string | number; label: string }[];
        search?: (search: string, lang: string, isHeader?: boolean) => Promise<{ value: string; label: string }[]>;
        placeholder: string;
      };
    };
  };
}

export const GoodsItemDocumentFormSchema: GoodsItemDocumentFormSchemaProps = {
  [TransitDocumentsEnum.PREVIOUS_DOCUMENT]: {
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL214', search, lang),
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
      complementOfInformation: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Complement Of Information',
      },
    },
  },
  [TransitDocumentsEnum.SUPPORTING_DOCUMENT]: {
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL214', search, lang),
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
      documentLineItemNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Document Line Item Number',
      },
      complementOfInformation: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Complement Of Information',
      },
    },
  },
  [TransitDocumentsEnum.TRANSPORT_DOCUMENT]: {
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL214', search, lang),
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
    },
  },
  [TransitDocumentsEnum.ADDITIONAL_REFFERENCE]: {
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL214', search, lang),
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
    },
  },
  [TransitDocumentsEnum.ADDITIONAL_INFORMATION]: {
    fields: {
      code: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL214', search, lang),
        placeholder: 'Code',
      },
      text: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Text',
      },
    },
  },
};
