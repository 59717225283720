import { useEffect, useRef } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Transit, TransitStatus } from '@e-origin/shared';

import TransitsStatusIndicator from '../../../../src/pages/transits/transit-status-indicator/transit-status-indicator.component';
import { TableActionsIcons } from '../../../assets';
import customsSyncIcon from '../../../assets/icons/customs-sync-icon.svg';
import { CustomTableActionsComponent } from '../../../components';
import { customsSync, fetchTransits, sendOne } from '../../../stores/transitsSlice';

export const useTransitsColumns = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const actions = (row: Transit) => {
    return [
      {
        icon: TableActionsIcons.VIEW,
        text: 'View details',
        onClick: () => {
          history.push(`/transits-details/${row._id}`);
        },
      },
      {
        if: [TransitStatus.NOT_SENT, TransitStatus.REJECTED].includes(row.generalInfo?.status),
        icon: TableActionsIcons.SEND,
        text: 'Send',
        onClick: async () => {
          await sendOne(row._id);
          dispatch(fetchTransits({ persistPagination: true }));
        },
      },
      {
        if: [TransitStatus.SENT, TransitStatus.ACCEPTED].includes(row.generalInfo?.status),
        icon: customsSyncIcon,
        text: 'Customs sync',
        onClick: async () => {
          await customsSync(row._id);
          dispatch(fetchTransits({ persistPagination: true }));
        },
      },
    ];
  };

  const buildColumns = (): IDataTableColumn<Transit>[] => [
    {
      name: 'Status',
      width: '75px',
      selector: 'generalInfo.status',
      sortable: true,
      cell: (row) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          <TransitsStatusIndicator status={row.generalInfo.status} />
        </div>
      ),
    },
    {
      name: 'Transit ID',
      width: '100px',
      selector: 'counter',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'name',
      minWidth: '150px',
      sortable: true,
    },
    {
      name: 'Decl. type',
      width: '120px',
      selector: 'TransitOperation.declarationType',
    },
    {
      name: 'LRN',
      selector: 'TransitOperation.LRN',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'MRN',
      selector: 'TransitOperation.MRN',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Holder of transit proc.',
      selector: 'HolderOfTheTransitProcedure.ContactPerson.name',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'C.O. of Departure',
      selector: 'CustomsOfficeOfDeparture.referenceNumber',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'C.O. of  Destination',
      selector: 'CustomsOfficeOfDestinationDeclared.referenceNumber',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Amount to be covered',
      cell: (row) => 'To be defined',
      // selector: 'Guarantee.GuaranteeReference.amountToBeCovered',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Packages',
      cell: (row) => 'To be defined',
      // selector: 'Consignment.HouseConsignment.ConsignmentItem.TotalNumberOfPackages',
      minWidth: '150px',
      sortable: true,
    },
    // todo - addapt to work with array as per domain model
    // {
    //   name: 'C.O. of Departure',
    //   selector: 'CustomsOfficeOfDeparture.referenceNumber',
    //   minWidth: '200px',
    //   sortable: true,
    // },
    // {
    //   name: 'C.O. of  Destination',
    //   selector: 'CustomsOfficeOfDestinationDeclared.referenceNumber',
    //   minWidth: '200px',
    //   sortable: true,
    // },
    // {
    //   name: 'Amount to be covered',
    //   selector: 'Guarantee.GuaranteeReference.amountToBeCovered',
    //   minWidth: '200px',
    //   sortable: true,
    // },
    // {
    //   name: 'Packages',
    //   selector: 'Consignment.HouseConsignment.ConsignmentItem.NotalNumberOfPackages',
    //   minWidth: '200px',
    //   sortable: true,
    // },
    {
      name: 'Gross Mass',
      selector: 'Consignment.grossMass',
      sortable: true,
    },
    {
      name: 'Seals',
      selector: 'totalNumberOfSeals',
      sortable: true,
    },
    {
      name: 'C.R. of Departure',
      selector: '',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'C.R. of Destination',
      selector: '',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Deadline',
      selector: '',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Customer',
      selector: 'customer.name',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Template',
      selector: 'template.name',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Last update',
      selector: 'updatedAt',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row) => (
        <CustomTableActionsComponent
          actions={actions(row)}
          secondaryActions={[]}
          onClick={() => console.log(row)}
          disableAfterClick={true}
        />
      ),
    },
  ];
  const columns = useRef<IDataTableColumn<Transit>[]>(buildColumns());

  useEffect(() => {
    columns.current = buildColumns();
  });

  return columns;
};
