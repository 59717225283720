/* eslint-disable complexity */
import { RecursivePartial, TransitHouseConsignment } from '@e-origin/shared';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface HouseItemFormInitializerReturn extends RecursivePartial<TransitHouseConsignment> {}

export const houseFormIntializer = (item: TransitHouseConsignment): HouseItemFormInitializerReturn => ({
  countryOfDispatch: item?.countryOfDispatch || '',
  grossMass: item?.grossMass || '',
  referenceNumberUCR: item?.referenceNumberUCR || '',
});
