/* eslint-disable complexity */
/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
import { useRef, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { TableActionsIcons } from '../../../../assets';
import { Button, CustomTableActionsComponent } from '../../../../components';

import * as Style from './transit-details-house.style';
// import { GoodsItemsModal } from './goods-items-modal/goods-item-modal';
import { Transit, TransitHouseConsignment } from '@e-origin/shared';
import { HouseModal } from './house-modal/house-modal';

interface TransitDetailsHouseProps {
  transit: Transit;
  editDisabled: boolean;
}

export const TransitDetailsHouse: React.FC<TransitDetailsHouseProps> = ({ transit, editDisabled }) => {
  const [showModal, setShowModal] = useState(false);

  const selectedRow = useRef<TransitHouseConsignment>(null);

  const actionList = () => {
    return [
      {
        icon: TableActionsIcons.VIEW,
        text: 'Edit',
        onClick: () => {
          setShowModal(true);
        },
      },
      {
        icon: TableActionsIcons.DELETE,
        text: 'Delete',
        onClick: () => {
          // pass
        },
      },
    ];
  };

  const columns: IDataTableColumn<TransitHouseConsignment>[] = [
    {
      name: 'Consignor',
      cell: (row) => row.Consignor?.name,
      sortable: false,
    },
    {
      name: 'Consignee',
      cell: (row) => row.Consignee?.name,
      sortable: false,
    },
    {
      name: 'Country Of Dispatch',
      cell: (row) => row.countryOfDispatch,
      sortable: false,
    },
    {
      name: 'Gross mass',
      cell: () => 'N/A',
      sortable: false,
    },
    {
      name: 'Reference Number UCR',
      cell: (row) => row.referenceNumberUCR,
      sortable: false,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row) => (
        <CustomTableActionsComponent
          actions={actionList()}
          secondaryActions={[]}
          onClick={() => {
            selectedRow.current = row;
          }}
          disableAfterClick={true}
        />
      ),
    },
  ];

  return (
    <Style.GoodsItemsContainer>
      <Style.GoodsItemsActionButton>
        {!editDisabled && (
          <Button outline onClick={() => setShowModal(true)}>
            Add new
          </Button>
        )}
      </Style.GoodsItemsActionButton>
      <DataTable columns={columns} data={transit.Consignment.HouseConsignment || []} />
      {showModal && (
        <HouseModal
          houseConsignment={selectedRow.current}
          onHide={() => {
            setShowModal(false);
          }}
          editDisabled={editDisabled}
        />
      )}
    </Style.GoodsItemsContainer>
  );
};
