/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { retrieveCodes } from '../../../../../../../stores/settingsSlice';
import { Autocomplete, Input } from '../../../../../../../components';
import { FormContainer, FormRow, FormSection } from '../../../../../../../styles/common';
import { HouseItemFormInitializerReturn } from '../house-form-initializer';
import { useSelector } from 'react-redux';
import { selectDeclarant } from '../../../../../../../stores/declarantSlice';

interface HouseItemDataProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
}

export const HouseConsignmentData: React.FC<HouseItemDataProps> = (props) => {
  const { form } = props;

  const declarant = useSelector(selectDeclarant);

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0}>
          <FormRow>
            <Autocomplete
              width={25}
              widthUnit="%"
              placeholder="Country Of Dispatch"
              fetchOptions={(search: string) => retrieveCodes('CL008', search, declarant.language)}
              onChange={(selectedOption) => form.setFieldValue('countryOfDispatche', selectedOption?.value)}
              value={{
                value: form.values.countryOfDispatch,
                label: form.values.countryOfDispatch,
              }}
              disabled={props.disabled}
              compact
            />

            <Input
              name="grossMass"
              placeholder="Gross Mass"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.grossMass}
              width={25}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />

            <Input
              name="referenceNumberUCR"
              placeholder="Reference Number UCR"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.referenceNumberUCR}
              width={25}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
        </FormSection>
      </FormContainer>
    </>
  );
};
