/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { useState } from 'react';

import { Button, Spinner, Tab, Tabs } from '../../../../../../components';

import ModalStyle from '../../../../../../styles/modal';
import { GoodsItemFormInitializerReturn, goodsItemFormIntializer } from './goods-item-form-initializer';

import * as Style from './goods-item-form.style';
import { GoodsItemData, GoodsItemDocuments, GoodsItemStakeholders, GoodsItemTransport } from './tabs';

interface GoodsItemProps {
  goodsItems: any;
  onHide(): void;
  disabled: boolean;
}

export const GoodsItemForm: React.FC<GoodsItemProps> = ({ goodsItems, onHide, disabled }) => {
  const [isSaving, setIsSaving] = useState(false);

  const form = useFormik<GoodsItemFormInitializerReturn>({
    initialValues: goodsItemFormIntializer(goodsItems),
    onSubmit: async () => {
      setIsSaving(true);

      // pass

      onHide();
      setIsSaving(false);
    },
  });

  const handleSubmit = async () => {
    if (!isSaving) {
      await form.submitForm();
    }
  };

  return (
    <Style.Container>
      <Tabs style={{ margin: '0 15px 15px 15px' }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
        <Tab label="Item Data">
          <GoodsItemData form={form} goodsItems={goodsItems} disabled={disabled} />
        </Tab>
        <Tab label="Stakeholders">
          <GoodsItemStakeholders form={form} goodsItems={goodsItems} disabled={disabled} />
        </Tab>
        <Tab label="Transport">
          <GoodsItemTransport form={form} goodsItems={goodsItems} disabled={disabled} />
        </Tab>
        <Tab label="Documents">
          <GoodsItemDocuments form={form} goodsItems={goodsItems} disabled={disabled} />
        </Tab>
      </Tabs>
      <ModalStyle.Footer>
        <Button type="button" onClick={onHide}>
          Cancel
        </Button>
        {!disabled && (
          <Button type="button" primary onClick={handleSubmit}>
            {isSaving ? (
              <>
                <Spinner isContrast />
                Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        )}
      </ModalStyle.Footer>
    </Style.Container>
  );
};
