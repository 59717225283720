import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    flex-1
    h-full
    overflow-hidden
  `}
`;
