/* eslint-disable complexity */

import { FormRow } from '../../../styles/common';
import { Autocomplete, Button, Input } from '../../../components';
import trashIcon from '../../../assets/icons/trash-icon.svg';
import * as Style from './duplicate-transit-row.style';
import { FormikProps } from 'formik';

interface InputConfig {
  type: 'Autocomplete' | 'Input';
  name: string;
  search?: (searchText: string) => Promise<{ value: string; label: string }[]>;
  label: string;
  width?: number;
}

interface TransitDuplicateRowProps {
  form: FormikProps<any>;
  inputs: InputConfig[];
  compact?: boolean;
  arrayPath: string;
  addButtonLabel: string;
}

export const TransitDuplicateRow: React.FC<TransitDuplicateRowProps> = ({
  form,
  inputs,
  arrayPath,
  addButtonLabel,
  compact,
}) => {
  const rows = (form.getFieldProps(arrayPath).value as any[]) || [];

  const addRow = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    form.getFieldProps(arrayPath).value as any[];
    rows.push({});
    form.setFieldValue(arrayPath, rows);
  };

  const removeRow = (rowIdx: number) => {
    form.getFieldProps(arrayPath).value as any[];
    rows.splice(rowIdx, 1);
    form.setFieldValue(arrayPath, rows);
  };

  return (
    <>
      {rows.map((_, rowIdx) => (
        <FormRow key={rowIdx}>
          {inputs.map((inputConfig, inputConfigIdx) => (
            <>
              {inputConfig.type === 'Autocomplete' ? (
                <Autocomplete
                  key={inputConfigIdx}
                  placeholder={`${inputConfig.label} ${rowIdx + 1}`}
                  fetchOptions={inputConfig.search}
                  onChange={(selectedOption) =>
                    form.setFieldValue(`${arrayPath}[${rowIdx}].${inputConfig.name}`, selectedOption.value)
                  }
                  value={{
                    value: form.values[`${arrayPath}[${rowIdx}].${inputConfig.name}`] || '',
                    label: form.values[`${arrayPath}[${rowIdx}].${inputConfig.name}`] || '',
                  }}
                  width={inputConfig.width || 100}
                  widthUnit="%"
                  compact={!!compact}
                />
              ) : (
                <Input
                  key={inputConfigIdx}
                  placeholder={`${inputConfig.label} ${rowIdx + 1}`}
                  onChange={(evt) =>
                    form.setFieldValue(`${arrayPath}[${rowIdx}].${inputConfig.name}`, evt.target.value)
                  }
                  name={`${arrayPath}[${rowIdx}].${inputConfig.name}`}
                  width={inputConfig.width || 100}
                  widthUnit="%"
                  defaultBehaviour
                  compact={!!compact}
                />
              )}
            </>
          ))}
          <Style.DeleteButton onClick={() => removeRow(rowIdx)}>
            <img src={trashIcon} alt="Remove" style={{ cursor: 'pointer', width: '16px', height: '20px' }} />
          </Style.DeleteButton>
        </FormRow>
      ))}

      <Style.Item>
        <Button outline onClick={addRow}>
          {addButtonLabel}
        </Button>
      </Style.Item>
    </>
  );
};

export default TransitDuplicateRow;
