/* eslint-disable complexity */
import { FormContainer, FormRow, FormSection } from '../../../../../../../../styles/common';
import Icons from '../../../../../../../../assets';
import { Button, CustomModal, Dropdown } from '../../../../../../../../components';
import ModalStyle from '../../../../../../../../styles/modal';
import { TransitDocumentsEnum } from '@e-origin/shared';
import { useEffect, useRef, useState } from 'react';
import { GoodsItemDocumentFormFactory } from './goods-item-document-form-factory';

interface GoodsItemDocumentModalProps {
  onHide: () => void;
  docDetails?: any;
  editDisabled: boolean;
  onSave: (savedDocument: any) => void;
}

export const documentsLabels = {
  [TransitDocumentsEnum.PREVIOUS_DOCUMENT]: 'Previous document',
  [TransitDocumentsEnum.SUPPORTING_DOCUMENT]: 'Supporting document',
  [TransitDocumentsEnum.TRANSPORT_DOCUMENT]: 'Transport document',
  [TransitDocumentsEnum.ADDITIONAL_REFFERENCE]: 'Additional reference',
  [TransitDocumentsEnum.ADDITIONAL_INFORMATION]: 'Additional information',
};

export const GoodsItemDocumentModal: React.FC<GoodsItemDocumentModalProps> = (props) => {
  const [documentType, setDocumentType] = useState<TransitDocumentsEnum>(null);

  const [documentData, setDocumentData] = useState<any>(undefined);

  const documentsTypesList = useRef(
    Object.keys(documentsLabels).map((key) => ({ value: key, label: documentsLabels[key] })),
  );

  const handleDocumentChange = (data) => {
    setDocumentData({ ...data });
  };

  const onSubmit = () => {
    props.onSave({
      id: documentData.id,
      type: documentType,
      operation: documentData.operation,
      data: {
        ...documentData.data,
      },
    });
    props.onHide();
  };

  useEffect(() => {
    if (props.docDetails) {
      // initialObject.current = (props.docDetails.dataSource as any) || null;
      setDocumentType(props.docDetails.type);
      // setHeader(typeof props.docDetails.dataSource.header === 'boolean' ? props.docDetails.dataSource.header : true);
      setDocumentData(props.docDetails.dataSource);
    }
  }, []);

  return (
    <CustomModal show={true} onHide={() => props.onHide()}>
      <ModalStyle.Header>
        <ModalStyle.Title>{props.docDetails ? 'Edit document' : 'Add new document'}</ModalStyle.Title>
        <ModalStyle.HeaderActions>
          <button onClick={() => props.onHide()}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <ModalStyle.Content style={documentType ? { overflow: 'auto' } : {}}>
        <FormContainer>
          <FormSection verticalPadding={20} topPadding={0}>
            <FormRow>
              <Dropdown
                placeholder="Type of document"
                options={documentsTypesList.current}
                onChange={(selectedOption) => setDocumentType(selectedOption?.value)}
                value={documentsTypesList.current.find((item) => item.value === documentType)}
                width={100}
                widthUnit="%"
                maxMenuHeight={150}
                disabled={
                  props.editDisabled ||
                  !!Object.keys(documentData?.id || {}).filter((key) => !!documentData?.id?.[key]).length
                }
              />
            </FormRow>
            {documentType && (
              <GoodsItemDocumentFormFactory
                type={documentType}
                data={documentData}
                onChange={handleDocumentChange}
                editDisabled={props.editDisabled}
              />
            )}
          </FormSection>
        </FormContainer>
      </ModalStyle.Content>
      <ModalStyle.Footer>
        <Button type="button" primary key="save-modal-btn" onClick={onSubmit}>
          Save
        </Button>
      </ModalStyle.Footer>
    </CustomModal>
  );
};
