/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { TransitDocumentsEnum } from '@e-origin/shared';
import { FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { Button, CustomTableActionsComponent, NotificationModal } from '../../../../../../../../components';
import { TableActionsIcons } from '../../../../../../../../assets';

import * as Style from './goods-item-documents.style';
import { GoodsItemFormInitializerReturn } from '../../goods-item-form-initializer';
import { documentsLabels, GoodsItemDocumentModal } from './goods-item-document-modal';

// import { DeclarationDocumentsComponent } from '../../../../../../../components';

interface GoodsItemDocumentsProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  goodsItems: any;
  disabled: boolean;
}

export interface DocumentItemProps {
  type: TransitDocumentsEnum;
  label?: string;
  code: string;
  referenceNumber?: string;
  dateOfValidity?: string;
  dataSource: any;
  dataSourceIndex: number;
}

export const GoodsItemDocuments: React.FC<GoodsItemDocumentsProps> = (props) => {
  const { form } = props;

  const [data, setData] = useState<DocumentItemProps[]>([]);
  const selectedRow = useRef<DocumentItemProps>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleShowDocumentModal = () => {
    setShowModal(!showModal);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDocumentDelete = () => {
    if (selectedRow.current) {
      const documentType = selectedRow.current.type;
      const documentIndex = selectedRow.current.dataSourceIndex;
      form.setFieldValue(`documents.${documentType}`, [
        ...(form.values.documents[documentType] || []).filter((_, idx) => idx !== documentIndex),
      ]);
    }
    setShowDeleteModal(false);
  };

  const handleSavingDocument = (savedDocument: any) => {
    if (!selectedRow.current) {
      form.setFieldValue(`documents.${savedDocument.type}`, [
        ...(form.values.documents[savedDocument.type] || []),
        savedDocument,
      ]);
    }
  };

  useEffect(() => {
    const tableData: DocumentItemProps[] = [];
    for (const [documentType, documents] of Object.entries(form.values.documents as any)) {
      if (Array.isArray(documents)) {
        documents.forEach((item, itemIdx) =>
          tableData.push({
            type: documentType as TransitDocumentsEnum,
            label: documentsLabels[documentType],
            code: item.type || item.code || item.role || '',
            referenceNumber:
              item.referenceNumber ||
              item.text ||
              item.vatIdentificationNumber ||
              item.reference?.customsOfficeGuarantee?.referenceNumber ||
              '',
            dateOfValidity: item.dateOfValidity,
            dataSource: item,
            dataSourceIndex: itemIdx,
          }),
        );
      }
    }
    setData(tableData);
  }, [form.values.documents]);

  const columns: IDataTableColumn[] = [
    {
      name: 'Type of document',
      selector: 'label',
      sortable: false,
    },
    {
      name: 'Code',
      selector: 'code',
      sortable: false,
    },
    {
      name: 'Reference',
      selector: 'referenceNumber',
      sortable: false,
      width: '250px',
    },
    {
      name: 'Date of validity',
      selector: 'dateOfValidity',
      sortable: false,
      width: '100px',
    },
    {
      name: 'Actions',
      right: true,
      cell: (row: DocumentItemProps) => (
        <CustomTableActionsComponent
          actions={[
            {
              icon: TableActionsIcons.VIEW,
              text: 'View details',
              onClick: () => {
                setShowModal(true);
                // pass
              },
            },
            {
              icon: TableActionsIcons.DELETE,
              text: 'Delete',
              onClick: () => {
                // selectedRow.current = row;
                setShowDeleteModal(true);
              },
            },
          ]}
          noSpaceBetweenButtons
          onClick={() => {
            selectedRow.current = row;
          }}
        />
      ),
    },
  ];

  return (
    <Style.DocumentsContainer>
      <Style.DocumentsActionButton>
        {!props.disabled && (
          <Button outline onClick={toggleShowDocumentModal}>
            Add new
          </Button>
        )}
      </Style.DocumentsActionButton>
      <DataTable noHeader columns={columns} data={data || []} pagination />
      {showModal && (
        <GoodsItemDocumentModal
          onHide={toggleShowDocumentModal}
          docDetails={selectedRow.current?.dataSource}
          onSave={handleSavingDocument}
          editDisabled={props.disabled}
        />
      )}
      <NotificationModal
        title="Confirm document delete"
        confirmButtonText="Delete"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDocumentDelete}
        isDelete
      >
        <div>Are you sure you want to delete this document?</div>
      </NotificationModal>
    </Style.DocumentsContainer>
  );
};
