/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { Autocomplete, Input } from '../../../../../../../components';
import { selectDeclarant } from '../../../../../../../stores/declarantSlice';
import { retrieveCodes } from '../../../../../../../stores/settingsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../../../../styles/common';
import TransitDuplicateRow from '../../../../../duplicate-transit-row/duplicate-transit-row.component';
import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';

interface GoodsItemDataProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  goodsItems: any;
  disabled: boolean;
}

export const GoodsItemData: React.FC<GoodsItemDataProps> = (props) => {
  const { form } = props;

  const declarant = useSelector(selectDeclarant);

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0}>
          <FormRow>
            <Autocomplete
              width={25}
              widthUnit="%"
              placeholder="Declaration Type"
              fetchOptions={(search: string) => retrieveCodes('CL232', search, declarant.language)}
              onChange={(selectedOption) => form.setFieldValue('declarationType', selectedOption?.value)}
              value={{
                value: form.values.declarationType,
                label: form.values.declarationType,
              }}
              compact
              disabled={props.disabled}
            />
            <Autocomplete
              width={25}
              widthUnit="%"
              placeholder="Country Of Dispatch"
              fetchOptions={(search: string) => retrieveCodes('CL008', search, declarant.language)}
              onChange={(selectedOption) => form.setFieldValue('countryOfDispatche', selectedOption?.value)}
              value={{
                value: form.values.countryOfDispatch,
                label: form.values.countryOfDispatch,
              }}
              disabled={props.disabled}
              compact
            />
            <Autocomplete
              width={25}
              widthUnit="%"
              placeholder="Country Of Destination"
              fetchOptions={(search: string) => retrieveCodes('CL008', search, declarant.language)}
              onChange={(selectedOption) => form.setFieldValue('countryOfDestination', selectedOption?.value)}
              value={{
                value: form.values.countryOfDestination,
                label: form.values.countryOfDestination,
              }}
              disabled={props.disabled}
              compact
            />
            <Input
              name="referenceNumberUCR"
              placeholder="Reference Number UCR"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.referenceNumberUCR}
              width={25}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
          <FormRow>
            <Input
              name="Commodity.descriptionOfGoods"
              placeholder="Description of goods"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.descriptionOfGoods}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Commodity.hsCode"
              placeholder="HS code"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.CommodityCode.hsCode}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
          <TransitDuplicateRow
            addButtonLabel="Add new authorisation"
            form={form}
            inputs={[
              {
                type: 'Autocomplete',
                label: 'UNNumber',
                name: 'UNNumber',
                width: 25,
                search: (searchText) => retrieveCodes('CL008', searchText),
              },
            ]}
            compact
            arrayPath="Commodity.DangerousGoods"
          />
          <FormRow>
            <Input
              name="GoodsMeasure.grossMass"
              placeholder="Gross mass"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.GoodsMeasure.grossMass}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="GoodsMeasure.netMass"
              placeholder="Net mass"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.GoodsMeasure.netMass}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="GoodsMeasure.supplementaryUnits"
              placeholder="Supplementary Units"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.GoodsMeasure.supplementaryUnits}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
          <TransitDuplicateRow
            addButtonLabel="Add new authorisation"
            form={form}
            inputs={[
              {
                type: 'Autocomplete',
                label: 'Type of Packages',
                name: 'TypeOfPackages',
                search: (searchText) => retrieveCodes('CL008', searchText),
              },
              {
                type: 'Input',
                label: 'Number of Packages',
                name: 'NumberOfPackages',
              },
              {
                type: 'Input',
                label: 'Marks',
                name: 'ShippingMarks',
              },
            ]}
            arrayPath="Packaging"
            compact
          />
        </FormSection>
      </FormContainer>
    </>
  );
};
