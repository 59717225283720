/* eslint-disable default-case */
/* eslint-disable complexity */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Tab, Tabs } from '../../components';
import { clearTransits, fetchTransit, selectTransit } from '../../stores/transitsSlice';
import TransitDetailsHeader from './header/transit-details-header.component';
import { TransitDetailsCustoms } from './tabs/transit-details-customs/transit-details-customs.component';
import { TransitGeneralInfo } from './tabs/transit-details-general-info.component';
import * as Style from './transit-details.style';
import { TransitPersons } from './tabs/transit-details-persons.component';
import { TransitDetailsGoodsItems } from './tabs/transit-details-goods-items';
import { TransitDetailsHouse } from './tabs/transit-details-house';
import { useDispatch, useSelector } from 'react-redux';
import { TransitStatus } from '@e-origin/shared';

const TransitDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [editDisabled, setEditDisabled] = useState<boolean>(true);
  const transit = useSelector(selectTransit);
  useEffect(() => {
    dispatch(fetchTransit(id));

    return () => {
      dispatch(clearTransits());
    };
  }, []);
  useEffect(() => {
    if (transit) {
      const isRejected =
        transit.generalInfo?.status === TransitStatus.REJECTED ||
        transit.generalInfo?.status === TransitStatus.NOT_SENT;
      setEditDisabled(!isRejected);
    }
  }, [transit]);

  return (
    <Style.Container>
      {transit && (
        <>
          <TransitDetailsHeader transitDetails={transit} editDisabled={editDisabled} />
          <Tabs style={{ padding: '0 50px', marginTop: 20 }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
            <Tab label="General Info">
              <TransitGeneralInfo transit={transit} editDisabled={editDisabled} />
            </Tab>
            <Tab label="Persons">
              <TransitPersons transit={transit} editDisabled={editDisabled} />
            </Tab>
            <Tab label="Batches"></Tab>
            <Tab label="House consignments">
              <TransitDetailsHouse transit={transit} editDisabled={editDisabled} />
            </Tab>
            <Tab label="Goods Items">
              <TransitDetailsGoodsItems transit={transit} editDisabled={editDisabled} />
            </Tab>
            <Tab label="Customs State">
              <TransitDetailsCustoms transit={transit} />
            </Tab>
          </Tabs>
        </>
      )}
    </Style.Container>
  );
};

export default TransitDetails;
