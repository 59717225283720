/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../../styles/common';
import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';
import { Input } from '../../../../../../../components';

interface GoodsItemTransportProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  goodsItems: any;
  disabled: boolean;
}

export const GoodsItemTransport: React.FC<GoodsItemTransportProps> = (props) => {
  const { form } = props;

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0} noTopPadding>
          <FormSectionTitle noTopBorder>Transport charges</FormSectionTitle>
          <FormRow>
            <Input
              name="TransportCharges.methodOfPayment"
              placeholder="Method of payment"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.TransportCharges.methodOfPayment}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
        </FormSection>
      </FormContainer>
    </>
  );
};
