/* eslint-disable complexity */
/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
import { useEffect, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';

import { Transit } from '@e-origin/shared';

import { TableActionsIcons } from '../../../../assets';
import { Button, CustomTableActionsComponent } from '../../../../components';
import { GoodsItemModal } from './goods-item-modal/goods-item-modal';
import * as Style from './transit-details-goods-items.style';

interface TransitDetailsGoodsItemsProps {
  transit: Transit;
  editDisabled: boolean;
}

export const TransitDetailsGoodsItems: React.FC<TransitDetailsGoodsItemsProps> = ({ transit, editDisabled }) => {
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (transit) {
      const data = [];
      for (const houseItem of transit.Consignment?.HouseConsignment || []) {
        for (const consignmentItem of houseItem.ConsignmentItem || []) {
          data.push({
            houseConsignmentReference: houseItem.Consignor?.name,
            descriptionOfGoods: consignmentItem.Commodity?.descriptionOfGoods,
            hsCode: consignmentItem.Commodity?.CommodityCode?.hsCode,
            grossMass: consignmentItem.Commodity?.GoodsMeasure?.grossMass,
            typeOfPackages: consignmentItem.Packaging?.[0]?.typeOfPackages,
            numberOfPackages: consignmentItem.Packaging?.[0]?.numberOfPackages,
            shippingMarks: consignmentItem.Packaging?.[0]?.shippingMarks,
          });
        }
      }

      setTableData(data);
    }
  }, []);

  const actionList = () => {
    return [
      {
        // if: true
        icon: TableActionsIcons.VIEW,
        text: 'Edit',
        onClick: () => {
          setShowModal(true);
        },
      },
      {
        // if: true
        icon: TableActionsIcons.DELETE,
        text: 'Delete',
        onClick: () => {
          // pass
        },
      },
    ];
  };

  const columns: IDataTableColumn[] = [
    {
      name: 'House consignment reference',
      selector: 'houseConsignmentReference',
      sortable: false,
    },
    {
      name: 'Description of goods',
      selector: 'descriptionOfGoods',
      sortable: false,
    },
    {
      name: 'HS code',
      selector: 'hsCode',
      sortable: false,
    },
    {
      name: 'Gross mass',
      selector: 'grossMass',
      sortable: false,
    },
    {
      name: 'Type of packages',
      selector: 'typeOfPackages',
      sortable: false,
    },
    {
      name: 'Number of packages',
      selector: 'numberOfPackages',
      sortable: false,
    },
    {
      name: 'Marking',
      selector: 'shippingMarks',
      sortable: false,
    },
    {
      name: 'Actions',
      right: true,
      cell: () => (
        <CustomTableActionsComponent
          actions={actionList()}
          secondaryActions={[]}
          onClick={() => {
            // pass
          }}
          disableAfterClick={true}
        />
      ),
    },
  ];

  return (
    <Style.GoodsItemsContainer>
      <Style.GoodsItemsActionButton>
        {!editDisabled && (
          <Button outline onClick={() => setShowModal(true)}>
            Add new
          </Button>
        )}
      </Style.GoodsItemsActionButton>
      <DataTable columns={columns} data={tableData || []} />
      {showModal && (
        <GoodsItemModal
          onHide={() => {
            setShowModal(false);
          }}
          editDisabled={editDisabled}
        />
      )}
    </Style.GoodsItemsContainer>
  );
};
