/* eslint-disable complexity */
import { ConsignmentItem, RecursivePartial } from '@e-origin/shared';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GoodsItemFormInitializerReturn extends RecursivePartial<ConsignmentItem> {
  documents: {
    PreviousDocument: any[];
    SupportingDocument: any[];
    TransportDocument: any[];
    AdditionalReference: any[];
    AdditionalInformation: any[];
  };
}

export const goodsItemFormIntializer = (item: ConsignmentItem): GoodsItemFormInitializerReturn => ({
  declarationType: item?.declarationType || '',
  countryOfDispatch: item?.countryOfDispatch || '',
  countryOfDestination: item?.countryOfDestination || '',
  referenceNumberUCR: item?.referenceNumberUCR || '',
  Commodity: {
    descriptionOfGoods: item?.Commodity?.descriptionOfGoods || '',
    DangerousGoods: item?.Commodity?.DangerousGoods || [{ UNNumber: '' }],
    CommodityCode: {
      hsCode: item?.Commodity?.CommodityCode?.hsCode || '',
    },
    GoodsMeasure: {
      grossMass: item?.Commodity?.GoodsMeasure?.grossMass || '',
      netMass: item?.Commodity.GoodsMeasure?.netMass || '',
      supplementaryUnits: item?.Commodity?.GoodsMeasure?.supplementaryUnits || '',
    },
  },

  Packaging: item?.Packaging || [{ typeOfPackages: '', numberOfPackages: '', shippingMarks: '' }],
  Consignee: {
    identificationNumber: item?.Consignee?.identificationNumber || '',
    name: item?.Consignee?.name || '',
    Address: {
      streetAndNumber: item?.Consignee?.Address?.streetAndNumber || '',
      postcode: item?.Consignee?.Address?.postcode || '',
      city: item?.Consignee?.Address?.city || '',
      country: item?.Consignee?.Address?.country || '',
    },
  },
  AdditionalSupplyChainActor: item?.AdditionalSupplyChainActor || [{ role: '', identificationNumber: '' }],
  TransportCharges: {
    methodOfPayment: item?.TransportCharges?.methodOfPayment,
  },
  documents: {
    PreviousDocument: item?.PreviousDocument || [],
    SupportingDocument: item?.SupportingDocument || [],
    TransportDocument: item?.TransportDocument || [],
    AdditionalReference: item?.AdditionalReference || [],
    AdditionalInformation: item?.AdditionalInformation || [],
  },
});
