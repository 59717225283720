/* eslint-disable complexity */

import * as Style from './goods-item-modal.style';
import { CustomModal } from '../../../../../components';
import ModalStyle from '../../../../../styles/modal';
import Icons from '../../../../../assets';

import { GoodsItemForm } from './goods-item-form/goods-item-form';

export interface GoodsItemModalProps {
  goodsItem?: any;
  editDisabled: boolean;
  onHide(): void;
}

export const GoodsItemModal: React.FC<GoodsItemModalProps> = ({ goodsItem, editDisabled, onHide }) => {
  const context = goodsItem ? 'UPDATE' : 'CREATE';
  return (
    <CustomModal width={55} show={true} onHide={onHide}>
      <ModalStyle.Header>
        <Style.TitleContainer>
          <ModalStyle.Title>{context === 'CREATE' ? 'Create' : 'Update'} Goods Item</ModalStyle.Title>
        </Style.TitleContainer>

        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <GoodsItemForm goodsItems={goodsItem} onHide={onHide} disabled={editDisabled} />
    </CustomModal>
  );
};
