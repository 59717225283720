/* eslint-disable default-case */
/* eslint-disable complexity */
import { useHistory } from 'react-router-dom';

import { Transit } from '@e-origin/shared';

import backIcon from '../../../assets/icons/back-icon.svg';
import { Input } from '../../../components';
import * as Style from './transit-details-header.style';

interface TransitDetailsProps {
  transitDetails: Transit;
  editDisabled: boolean;
}

const TransitDetailsHeader: React.FC<TransitDetailsProps> = ({ transitDetails, editDisabled }) => {
  const history = useHistory();
  return (
    <Style.Header>
      <Style.BackButton
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={backIcon} alt="" />
        Back
      </Style.BackButton>
      <Style.Summary>
        <div>
          <label>Transit name</label>
          <p>{transitDetails.name}</p>
        </div>
        <div>
          <label>Status</label>
        </div>
      </Style.Summary>
      <Style.CommonInputs>
        <Style.CommonInputsRow>
          <Input
            placeholder="Declaration type"
            value={transitDetails?.TransitOperation.declarationType}
            disabled={editDisabled}
          />
          <Input placeholder="LRN" value={transitDetails?.TransitOperation.LRN} disabled={editDisabled} />
          <Input placeholder="MRN" value={transitDetails?.TransitOperation.MRN} disabled={editDisabled} />
          {/* todo - addapt to work with array as per domain model */}
          {/* <Input
            placeholder="Amount to be covered"
            value={transitDetails.Guarantee.GuaranteeReference.amountToBeCovered}
            disabled
          /> */}
          <Input
            placeholder="Number of Packages"
            value={transitDetails?.Consignment.totalNumberOfPackages}
            disabled={editDisabled}
          />
        </Style.CommonInputsRow>

        <Style.CommonInputsRow>
          <Input placeholder="Gross Mass" value={transitDetails?.Consignment.grossMass || ''} disabled={editDisabled} />
          <Input
            placeholder="Number of Seals"
            value={transitDetails?.Consignment.totalNumberOfSeals || ''}
            disabled={editDisabled}
          />
          <Input placeholder="Security" value={transitDetails?.TransitOperation.security} disabled={editDisabled} />
          <Input
            placeholder="Reduced Data set Indicator"
            value={transitDetails?.TransitOperation.reducedDatasetIndicator}
            disabled={editDisabled}
          />
          <Input
            placeholder="CustomsOfficeOfDestinationDeclared"
            value={transitDetails?.CustomsOfficeOfDestinationDeclared.referenceNumber || ''}
            disabled={editDisabled}
          />
        </Style.CommonInputsRow>
      </Style.CommonInputs>
    </Style.Header>
  );
};

export default TransitDetailsHeader;
