/* eslint-disable max-lines */
/* eslint-disable no-shadow */

export enum TransitDocumentsEnum {
  PREVIOUS_DOCUMENT = 'PreviousDocument',
  SUPPORTING_DOCUMENT = 'SupportingDocument',
  TRANSPORT_DOCUMENT = 'TransportDocument',
  ADDITIONAL_REFFERENCE = 'AdditionalReference',
  ADDITIONAL_INFORMATION = 'AdditionalInformation',
}

export enum DeclarationDocumentsEnum {
  GUARANTEE_DOCUMENTS = 'guaranteeDocuments',
  ADDITIONAL_FISCAL_REFERENCE = 'additionalFiscalReference',
  AUTHORISATION_DOCUMENTS = 'authorisationDocuments',
  ADDITIONS_AND_DEDUCTIONS = 'additionsAndDeductionsDocuments',
  TRANSPORT_DOCUMENTS = 'transportDocuments',
  PREVIOUS_DOCUMENTS = 'previousDocuments',
  SUPPORTING_DOCUMENTS = 'supportingDocuments',
  ADDITIONAL_REFFERENCE = 'additionalReference',
  ADDITIONAL_INFORMATION = 'additionalInformation',
  WAREHOUSE_DOCUMENT = 'warehouseDocuments',
}
export enum SortDirection {
  DESC = 'desc',
  ASC = 'asc',
}

export enum RefWebValueMethod {
  FIXEDPERC = 'FIXEDPERC',
  AMAZON = 'AMAZON',
  CUSTOMER = 'CUSTOMER',
}

export enum GoodsLocationType {
  DESIGNATED_LOCATION = 'A',
  AUTHORISED_PLACE = 'B',
  APPROVED_PLACE = 'C',
  OTHER = 'D',
}

export enum GoodsLocationQualifierIdentification {
  POSTCODE_ADDRESS = 'T',
  UN_LOCOCDE = 'U',
  CUSTOMS_OFFICE = 'V',
  GNSS = 'W',
  EORI = 'X',
  AUTHORISATION_NUMBER = 'Y',
  ADDRESS = 'Z',
}

export enum PostFlowDeclarationTypes {
  A = 'A',
  B = 'B',
  C = 'C',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

export enum PreFlowDeclarationTypes {
  D = 'D',
  E = 'E',
  F = 'F',
}

export enum BatchStatusEnum {
  ARCHIVED = 'archived',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress',
  IN_VALIDATION = 'in_validation',
  SCRAPING = 'scraping',
  SENDING = 'sending',
  SUCCESS = 'success',
  SYNCING = 'syncing',
  ANALYSIS = 'analysis',
}

export enum MappingType {
  STRING = 'string',
  NUMBER = 'number',
  ALPHA_NUMERIC = 'alphaNumeric',
  VARIABLE = 'variable',
}

export enum DeclarationCustomsType {
  BE_GATE = 'BE_GATE',
  PLDA = 'PLDA',
  IDMS = 'IDMS',
  AES = 'AES',
  DECO = 'DECO',
  DMS = 'DMS',
}

export enum EnvironmentCountries {
  BE = 'BE',
  NL = 'NL',
}

export enum DeclarationCustomsFlowType {
  PRE = 'PRE',
  POST = 'POST',
}

export enum DeclarationStatus {
  NOT_SENT = 'NOT_SENT',
  SENDING = 'SENDING',
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  NOT_RELEASED = 'NOT_RELEASED',
  RELEASED = 'RELEASED',
  EXPORTED = 'EXPORTED', // only for export
  INVALIDATED = 'INVALIDATED',
  ANALYSIS = 'ANALYSIS',
  IN_CONTROL = 'IN_CONTROL',
  BEGATE_SENT = 'BEGATE_SENT',
  BEGATE_RELEASED = 'BEGATE_RELEASED',
  BEGATE_NOT_RELEASED = 'BEGATE_NOT_RELEASED',
}

export enum ImporterStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  NOK = 'NOK',
  OK = 'OK',
  INVALID = 'INVALID',
  EMPTY = 'EMPTY',
}

export enum BegateCustomsStateStatus {
  FREED = 'freed',
  BLOCKED = 'blocked',
  ERROR = 'error',
}

export enum FilterRisksValues {
  HS_DESCRIPTION_OK = 'hsDescriptionOk',
  INVOICED_RATE_WEB_OK = 'invoicedRateWebOk',
  WEB_MATCHING_DESCRIPTION = 'webMatchingDescription',
  SPLIT_SHIPMENT = 'splitShipment',
  WRONG_IOSS = 'wrongIOSS',
  UNDERVALUATION = 'undervaluation',
}

export enum DeclarationAdditionalProcedure {
  C07 = 'C07',
  C08 = 'C08',
  F48 = 'F48',
  F49 = 'F49',
}

export enum DeclarationTransportDocumentAmount {
  N740 = 'N740',
}

export enum DeclarationPreviousDocumentType {
  NMRN = 'NMRN',
}

export enum DeclarationLogType {
  SENT = 'sent',
  RECEIVED = 'received',
}

export enum DeclarationMessageInfoDeclarationType {
  Y = 'Y',
  A = 'A',
  D = 'D',
  IM = 'IM',
  EU = 'EU',
  EX = 'EX',
}

export enum DeclarationDocumentsAdditionalFiscalReferenceRole {
  IMPORTER = 'FR1',
  CUSTOMER = 'FR2',
  TAX_REPRESENTATIVE = 'FR3',
  HOLDER = 'FR4',
  VENDOR = 'FR5',
  TAXABLE_PERSON = 'FR7',
}

export enum RepresentativeStatus {
  DIRECT = 2,
  INDIRECT = 3,
}

// export enum IDMSSendMessageCode
export enum IDMSLVSendMessageType {
  NEW_DECLARATION = 'IE001',
  INVALIDATION_REQUEST = 'IE414',
}

export enum IDMSHVSendMessageType {
  DECLARATION = 'DECLARATION',
  PRESENTATION = 'PRESENTATION',
  INVALIDATE = 'INVALIDATE',
  AMENDMENT = 'AMENDMENT',
}

export enum IDMSHVSendMessageCode {
  NEW_DECLARATION = 'IE415B',
  INVALIDATION_REQUEST = 'IE414B',
  IMPORT_PRESENTATION = 'IE432B',
  IMPORT_AMENDMENT = 'IE413B',

  EXPORT_NEW_DECLARATION = 'CC515C',
  EXPORT_PRESENTATION = 'CC511C',
  EXPORT_INVALIDATION_REQUEST = 'CC514C',
  EXPORT_AMENDMENT = 'CC513C',
}

export enum IDMSHVMessageType {
  IE426B = 'IE426B', // - registration (pre)
  IE460B = 'IE460B', // - control notification (pre, post)
  IE428B = 'IE428B', // - acceptance (post)
  IE429B = 'IE429B', // - release (post)
  IE410B = 'IE410B', // - invalidation decision
  IE456B = 'IE456B', // - functional rejection
  IE451B = 'IE451B', // - Notification for non release
  IE404B = 'IE404B', // - Amendment acceptance
}

export enum IDMSExportAsyncResponseCode {
  ACKNOWLEDGE = 'cc928c',
  INVALID = 'cc917c',
}

export enum IDMSAsyncResponseCode {
  ACKNOWLEDGE = 'ie928',
  INVALID = 'ie906',
}

export enum IDMSLVSyncResponseCode {
  ACCEPTED = 'ie428',
  REJECTED = 'ie416',
  RELEASED = 'ie429',
  INVALIDATED = 'ie410',
  NOT_RELEASED = 'ie451',
  IN_CONTROL = 'ie460',
}

export enum IDMSExportHVSyncResponseCode {
  ACCEPTED = 'cc528c',
  REJECTED = 'cc556c',
  RELEASED = 'cc529c',
  INVALIDATED = 'cc509c',
  NOT_RELEASED = 'cc551c',
  IN_CONTROL = 'cc560c',
  EXPORTED = 'cc599c',
  AMENDMENT_ACCEPTED = 'cc504c',
  AMENDMENT_STATUS = 'cc595c',
}

export enum IDMSExportHVSyncStatusFromResponseCode {
  cc528c = 'ACCEPTED',
  cc556c = 'REJECTED',
  cc529c = 'RELEASED',
  cc509c = 'INVALIDATED',
  cc551c = 'NOT_RELEASED',
  cc560c = 'IN_CONTROL',
  cc599c = 'EXPORTED',
  cc504c = 'AMENDMENT_ACCEPTED',
  cc595c = 'AMENDMENT_STATUS',
}

export enum IDMSHVSyncResponseCode {
  ACCEPTED = 'ie428b',
  REGISTRATION = 'ie426b',
  REJECTED = 'ie456b',
  RELEASED = 'ie429b',
  INVALIDATED = 'ie410b',
  NOT_RELEASED = 'ie451b',
  IN_CONTROL = 'ie460b',
  AMENDMENT_ACCEPTED = 'ie404b',
}

export enum IDMSHVSyncStatusFromResponseCode {
  ie428b = 'ACCEPTED',
  ie426b = 'ACCEPTED',
  ie456b = 'REJECTED',
  ie429b = 'RELEASED',
  ie410b = 'INVALIDATED',
  ie451b = 'NOT_RELEASED',
  ie460b = 'IN_CONTROL',
  ie404b = 'AMENDMENT_ACCEPTED',
}

export enum IDMSLVSyncStatusFromResponseCode {
  ie428 = 'ACCEPTED',
  ie416 = 'REJECTED',
  ie429 = 'RELEASED',
  ie410 = 'INVALIDATED',
  ie451 = 'NOT_RELEASED',
  ie460 = 'IN_CONTROL',
}

export enum NlDecoMessageType {
  CC410A = 'CC410A', // - invalidated
  CC426A = 'CC426A', // - registration (pre)
  CC428A = 'CC428A', // - acceptance (post)
  CC429A = 'CC429A', // - release (post)
  CC451A = 'CC451A', // - no release (post)
  CC456A = 'CC456A', // - functional rejection
  CC460A = 'CC460A', // - control notification (pre, post)
  XMLCNT = 'XMLCNT', // - technical rejection
  CCTAXA = 'CCTAXA', // - tax calculation (post)
}

export enum NlDmsMessageType {
  CC410A = 'CC410A', // - invalidated
  CC426A = 'CC426A', // - registration (pre)
  CC428A = 'CC428A', // - acceptance (post)
  CC429A = 'CC429A', // - release (post)
  CC451A = 'CC451A', // - no release (post)
  CC456A = 'CC456A', // - functional rejection
  CC460A = 'CC460A', // - control notification (pre, post)
  XMLCNT = 'XMLCNT', // - technical rejection
  CCTAXA = 'CCTAXA', // - tax calculation (post)
  CCRCVA = 'CCRCVA', // - tax calculation (post)
}

export enum TransitTypeEnum {
  DEPARTURE = 'DEPARTURE',
  ARRIVAL = 'ARRIVAL',
}

export enum TransitStatus {
  INVALIDATION_PENDING = 'IN_CONTROL',
  INVALIDATED = 'INVALIDATED',
  GUARANTEE_NOT_VALID = 'GUARANTEE_NOT_VALID',
  NOT_SENT = 'NOT_SENT',
  SENDING = 'SENDING',
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  IN_CONTROL = 'IN_CONTROL',
  CORR_REFUSED_WAITING = 'CORR_REFUSED_WAITING',
  NOT_RELEASED = 'NOT_RELEASED',
  CORR_OK_WAITING = 'CORR_OK_WAITING',
  RELEASED = 'RELEASED',
  FINISHED = 'FINISHED',
}

export enum TransitSendMessageType {
  NEW_TRANSIT = 'ncts:CC015C',
}

export enum TransitLogType {
  SENT = 'sent',
  RECEIVED = 'received',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
}

export enum TransitCustomsFlowType {
  PRE = 'PRE',
  POST = 'POST',
}

export enum NCTSResponseType {
  ACKNOWLEDGE = 'AcknowledgementMessage',
  INVALID = 'CC917C', // - technical rejection
  ACCEPTED = 'CC028C', // - acceptance
  RELEASED = 'CC029C', // - released
  REJECTED = 'CC056C', // - functional rejection
  NOT_RELEASED = 'CC051C', // not released
  GUARANTEE_NOT_VALID = 'CC055C', // guarantee not valid
  FINISHED = 'CC045C', // - finished
}

export enum BatchStatisticType {
  ACCEPTED = 'ACCEPTED',
  RELEASED = 'RELEASED',
  REJECTED = 'REJECTED',
  NOT_RELEASED = 'NOT_RELEASED',
  INVALIDATED = 'INVALIDATED',
  ERROR_NOT_SENT = 'ERROR_NOT_SENT',
  SENT = 'SENT',
  IN_CONTROL = 'IN_CONTROL',
}

export enum MethodsOfPayment {
  PAYMENT_IN_CASH = 'A',
  PAYMENT_BY_CREDIT_CARD = 'B',
  PAYMENT_BY_CHEQUE = 'C',
  OTHER = 'D',
  DEFERRED_OR_POSTPONED_PAYMENT = 'E',
  POSTPONED_PAYMENT_VAT_SYSTEM = 'G',
  ELECTRONIC_CREDIT_TRANSFER = 'H',
  PAYMENT_THROUGH_POST_OFFICE_ADMINISTRATION = 'J',
  EXCISE_CREDIT_OR_REBATE = 'K',
  GUARANTEE_LODGED_WITH_INTERVATION_AGENCY = 'O',
  FROM_AGENTS_CASH_ACCOUNT = 'P',
  GUARANTEE_OF_THE_AMOUNT_PAYABLE = 'R',
  INDIVIDUAL_GUARANTEE_ACCOUNT = 'S',
  FROM_AGENT_GUARANTEE_ACCOUNT = 'T',
  FROM_AGENT_GUARANTEE_STANDING_AUTHORITY = 'U',
  FROM_AGENT_GUARANTEE_INDIVIDUAL_AUTHORITY = 'V',
}

export enum RealTimeEvents {
  BATCH_SEND_ALL_FINISHED = 'batch-send-all-finished',
  BATCH_SYNC_ALL_FINISHED = 'batch-sync-all-finished',
  FILE_UPLOAD_RESULT = 'file-upload-result',
  BATCH_UPDATE_RESULT = 'batch-update-result',
  FILE_PROCESS_PROGRESS = 'file-process-progress',
  FILE_PROCESS_FAILED = 'file-process-failed',
  BATCHES_SYNC_STATUS = 'batches-sync-status',
  SCRAPING_PROGRESS = 'scraping_progress',
  SCREENSHOT_PROGRESS = 'screenshot_progress',
  STARTING_TO_SEND = 'starting-to-send',
  SENDING_PROGRESS = 'sending-progress',
  INVALIDATING_PROGRESS = 'invalidating-progress',
  SYNCING_PROGRESS = 'syncing-progress',
}

export enum VerticalViewColumns {
  FAILED = 'FAILED',
  RISK_ANALYSIS = 'RISK_ANALYSIS',
  BEGATE_NOT_RELEASED = 'BEGATE_NOT_RELEASED',
  BEGATE_RELEASED = 'BEGATE_RELEASED',
  NOT_SENT = 'NOT_SENT',
  VALIDATION_IN_PROGRESS = 'VALIDATION_IN_PROGRESS',
  REJECTED = 'REJECTED',
  IN_CONTROL = 'IN_CONTROL',
  NOT_RELEASED = 'NOT_RELEASED',
  RELEASED = 'RELEASED',
  INVALIDATED = 'INVALIDATED',
}

export enum DeclarationViewEnum {
  DECLARATION_GROUP = 'DECLARATION_GROUP',
  HIGH_VALUES = 'HIGH_VALUES',
  LOW_VALUES = 'LOW_VALUES',
}

export enum BatchTypeEnum {
  HIGH_VALUES = 'HIGH_VALUES',
  HIGH_VALUES_EXPORT = 'HIGH_VALUES_EXPORT',
  LOW_VALUES = 'LOW_VALUES',
}

export enum TemplateType {
  LOW_VALUE_H7 = 'low_value_h7',
  HIGH_VALUE_H1 = 'high_value_h1',
  INVOICE = 'invoice',
  CUSTOMS_REPORT = 'customs_report',
  POA = 'POA',
  VAT_GUARANTEE = 'VAT_GUARANTEE',
  TRANSIT = 'TRANSIT',
}

export enum ReferenceValueType {
  INVOICE = 'INVOICE',
  WEB = 'WEB',
  IMADB = 'IMADB',
  IMADB2 = 'IMADB2',
}

export enum DutiesAndTaxesTaxType {
  DUTY_A0 = 'A0',
  VAT_B0 = 'B0',
}

export enum AdditionalWebFileType {
  FIRST_IMAGE = 'first_image',
  SCREENSHOT = 'screenshot',
}

export default {
  GoodsLocationType,
  GoodsLocationQualifierIdentification,
  BatchStatusEnum,
  MappingType,
  DeclarationStatus,
  DeclarationAdditionalProcedure,
  DeclarationTransportDocumentAmount,
  DeclarationPreviousDocumentType,
  DeclarationLogType,
  DeclarationMessageInfoDeclarationType,
  DeclarationDocumentsAdditionalFiscalReferenceRole,
  IDMSLVSendMessageType,
  BatchStatisticType,
  MethodsOfPayment,
  RealTimeEvents,
  TemplateType,
  AdditionalWebFileType,
};
