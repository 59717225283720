import styled from 'styled-components';
import tw from 'twin.macro';

export const GoodsItemsContainer = styled.div`
  ${tw`
    flex
    flex-col
    flex-shrink-0
  `}
`;

export const GoodsItemsActionButton = styled.div`
  ${tw`
    flex
    py-3
    justify-end
  `}
`;
